<template>
  <div>
    <div class="header-container">
      <v-breadcrumbs>
        <v-breadcrumbs-item class="breadcrumbs-hover-default">
          <i class="fal fa-address-card" style="margin: 0 10px 0 0"></i>
          <span @click="main()">{{ $t("follow_schedule.title") }}</span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    main() {
      this.$router
        .push({
          name: "follow_schedule.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.content ul {
  list-style: disc outside;
  margin-left: 0.5em !important;
  margin-top: 1em;
}
</style>
